import React from "react"

import AppLayoutTemplate from "../frames/MainLayout"

const NotFoundPage = () => (

  <AppLayoutTemplate>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </AppLayoutTemplate>

)

export default NotFoundPage
